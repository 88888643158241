import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Caption from "../../../components/caption"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import PaperShred from "../../../components/paper-shred"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopFebruarPageQuery {
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/februar/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/februar/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      materialien: file(
        relativePath: { eq: "workshops/kalender/februar/materialien.jpg" }
      ) {
        ...largeImage
      }
      maske1: file(
        relativePath: { eq: "workshops/kalender/februar/maske-1.jpg" }
      ) {
        ...largeImage
      }
      maske2: file(
        relativePath: { eq: "workshops/kalender/februar/maske-2.jpg" }
      ) {
        ...largeImage
      }
      maske3: file(
        relativePath: { eq: "workshops/kalender/februar/maske-3.jpg" }
      ) {
        ...largeImage
      }
      maske4: file(
        relativePath: { eq: "workshops/kalender/februar/maske-4.jpg" }
      ) {
        ...largeImage
      }
      maske5: file(
        relativePath: { eq: "workshops/kalender/februar/maske-5.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-februar">
      <Seo
        title="Februar"
        description="Was für eine verrückte Zeit! Im Februar feiern wir Fasching. Neben Frühling, Sommer, Herbst und Winter zählen manche den Fasching sogar als die 5. Jahreszeit. Dafür wollen wir bunte Masken basteln. Zur Inspiration haben wir verschiedene Gefühlsausdrücke aus Hölderlins Gedichten für deine Masken gesammelt."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Februar",
              link: "/workshops/kalender/februar",
            },
          ]}
        />
        <SectionSpacing>
          <Stack>
            <PageTitle>Februar</PageTitle>
            <Paragraph dropcap={true}>
              Was für eine verrückte Zeit! Im Februar feiern wir Fasching. Neben
              Frühling, Sommer, Herbst und Winter zählen manche den Fasching
              sogar als die 5. Jahreszeit. Dafür wollen wir bunte Masken
              basteln. Zur Inspiration haben wir verschiedene Gefühlsausdrücke
              aus Hölderlins Gedichten für deine Masken gesammelt.
            </Paragraph>
            <Inline alignX="center" space={6}>
              <PaperShred bg="letter" color="text">
                feurigfroh
              </PaperShred>
              <PaperShred bg="letter" color="text">
                freundlichmurmelnd
              </PaperShred>
              <PaperShred bg="letter" color="text">
                herrlichgestimmt
              </PaperShred>
              <PaperShred bg="letter" color="text">
                schreckenvoll
              </PaperShred>
              <PaperShred bg="letter" color="text">
                kummervoll
              </PaperShred>
              <PaperShred bg="letter" color="text">
                liebetrunken
              </PaperShred>
              <PaperShred bg="letter" color="text">
                verzweiflungsvoll
              </PaperShred>
            </Inline>
          </Stack>

          <Stack>
            <Heading as="h2" level={2}>
              Du brauchst...
            </Heading>
            <Constrain>
              <Stack>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Den Zettel mit den Gefühlsausdrücken aus unserem Bastelset
                      oder eigene kleine Zettel, auf die du die Gefühlsausdrücke
                      aufschreiben kannst, zu denen du eine Maske basteln
                      möchtest.
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Kleine bunte Notizzettel oder Origami-Papier für die
                      Masken
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Auf einem Kalenderblatt mit dem Titel Januar ist eine gemalte Schneeflocke neben einer blauen Postkarte mit Hölderlins Gedicht der Winter aufgeklebt"
                />
              </Stack>
            </Constrain>
          </Stack>

          <Stack>
            <Heading as="h2" level={2}>
              Deine Aufgabe Schritt für Schritt:
            </Heading>

            <Constrain>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Entscheide, welche Gefühlsausdrücke auf deiner Kalenderseite
                    vorkommen sollen. Schreibe sie jeweils auf einen kleinen
                    Zettel oder schneide sie aus. Du kannst dir natürlich auch
                    eigene Wörter überlegen.
                  </Paragraph>
                </StepsBox>
                <Stack>
                  <StepsBox step={2}>
                    <Paragraph>
                      Kannst du dir schon vorstellen, wie die passenden Masken
                      dazu aussehen könnten? Dann geht es jetzt an's Masken
                      schneiden. Folge Schritt für Schritt der Anleitung:
                    </Paragraph>
                  </StepsBox>
                  <Stack space={3}>
                    <Image
                      image={data.maske1.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <Caption align="left">
                      Nimm dir eines der bunten Blätter aus dem Briefumschlag.
                    </Caption>
                  </Stack>
                  <Stack space={3}>
                    <Image
                      image={data.maske2.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <Caption align="left">
                      Falte das Blatt einmal in der Mitte.
                    </Caption>
                  </Stack>
                  <Stack space={3}>
                    <Image
                      image={data.maske3.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <Caption align="left">
                      Schneide nun den Mund und die Nase an der gefalteten Seite
                      ein. Für die Augen musst in der Blattmitte ein Loch
                      machen. Augen, Nase und Mund können verschiedene Formen
                      haben- je nach dem welchen Gefühlsausdruck du darstellen
                      möchtest.
                    </Caption>
                  </Stack>
                  <Stack space={3}>
                    <Image
                      image={data.maske4.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <Caption align="left">
                      Wenn du die Ecken beschneidest, kannst du der Maske eine
                      besondere Form geben.
                    </Caption>
                  </Stack>
                  <Stack space={3}>
                    <Image
                      image={data.maske5.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <Caption align="left">
                      Zuletzt klappst du dein gefaltetes Blatt wieder auf! Wo
                      vorher nur ein Auge und ein halber Mund zu sehen waren,
                      ist nun ein fertiges Gesicht.
                    </Caption>
                  </Stack>
                </Stack>
                <StepsBox step={3}>
                  <Paragraph>
                    Wiederhole die Schritte und bastle weitere Masken. Deine
                    Masken können ganz verschieden aussehen.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Zuletzt klebst du die Masken auf deinen Kalender. Du kannst
                    die kleinen Zettel mit den Gefühlsausdrücken bemalen und wie
                    Konfetti auf deine Kalenderseite kleben oder deine Seite
                    beliebig verzieren.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Constrain>
          </Stack>

          <Stack space={6}>
            <Heading as="h2" level={4}>
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Auf einem Kalenderblatt mit dem Titel Januar ist eine gemalte Schneeflocke neben einer blauen Postkarte mit Hölderlins Gedicht der Winter aufgeklebt"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Auf einem Kalenderblatt mit dem Titel Januar ist eine gemalte Schneeflocke neben einer blauen Postkarte mit Hölderlins Gedicht der Winter aufgeklebt"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Trivia>
                In Hölderlins Gedichten kommen die Adjektive schön und heilig am
                häufigsten vor. Aber auch die Beschreibungen lieb, süß, froh und
                freundlich verwendet er häufiger.
              </Trivia>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </SectionSpacing>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
